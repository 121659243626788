import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { Grid } from '@material-ui/core';
import Layout from '../components/layout';
import Banner from '../components/banner';

export default function TermsOfUse({ data }) {
  const { page: { title, content } } = data;
  return (
    <Layout>
      <div style={{ padding: '20px' }}>
        <div id="back-to-top-anchor" />
        <Banner title={title} />
        <Grid className="privacyContainer">
          <div className="content" dangerouslySetInnerHTML={{ __html: content }} />
        </Grid>
      </div>
      <div
        style={{
          padding: 20,
          fontSize: 15,
          paddingTop: '0',
        }}
      >
        Last Updated: 7/29/2021
      </div>
    </Layout>
  );
}

TermsOfUse.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
};

export const query = graphql`query {
   page: wpPage(title: { eq: "Terms of Use" }) {
       title
       content
     }
 }`;
